
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import CommodityGroupsParamItem from "@/components/commodity-groups/CommodityGroupsParamItem.vue";
import params from "@/services/params";
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';

export default defineComponent({
  name: 'CommodityGroupsParamModal',
  components: { BaseCheckbox, ModalWrapper, CommodityGroupsParamItem },
  data: () => ({
    changesCommodityGroupsParam: {
      name: null,
      units: null,
      mandatory: null
      // tecdocId: null, показать если он есть
    } as any
  }),
  props: {
    param: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    checkValues(): any {
      return Object.values(this.changesCommodityGroupsParam).every(el => el === null)
    }
  },
  methods: {
    toggleRequiredParam() {
      this.changesCommodityGroupsParam.mandatory = !this.param.mandatory
    },
    changeValue(field: string, value: string) {
      this.changesCommodityGroupsParam[field] = value
    },
    saveChanges() {
      const payload = {
        ...this.param,
        ...(this.changesCommodityGroupsParam.name && {name: this.changesCommodityGroupsParam.name}),
        units: this.changesCommodityGroupsParam.units,
        mandatory: this.changesCommodityGroupsParam.mandatory
      }

      params.updateParam(this.param.id, payload).then((response: any) => {
        this.$emit('reload', response.data)
        this.$emit('closeModal')
      })

    }
  },
})
